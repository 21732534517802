<template>
  <div v-if="!room">

    <div
      :id="getContainerId(null, true)"
      class="source-position"
    ></div>

    <div
        :id="getContainerId(null, false)"
        class="source-position"
    ></div>

  </div>
  <div v-else-if="participants.length">

    <div
      v-for="participant in participants"
      :key="'cont' + participant.identity"
    >
      <div
          :id="getContainerId(participant, true)"
          class="source-position"
      ></div>

      <div
          :id="getContainerId(participant, false)"
          class="source-position"
      ></div>
    </div>

  </div>
</template>

<script>
import { Room } from "livekit-client";
import { mapGetters } from "vuex";
import { VIDEO_CONT_PREFIX, AUDIO_CONT_PREFIX } from "@/store/settings";

export default {
  name: 'MediaContainers',
  props: {
    room: {
      type: Room,
      default: null,
    },
    participants: {
      type: Array,
      default: [],
    }
  },
  computed: {
    ...mapGetters({
      username: 'user/username'
    })
  },
  methods: {
    getContainerId (participant, isVideo) {
      const prefix = isVideo ? VIDEO_CONT_PREFIX : AUDIO_CONT_PREFIX;
      const postfix = participant ? participant.identity : this.username;
      return prefix + postfix;
    }
  }
}
</script>

<style scoped>
.source-position {
  position: absolute;
  visibility: hidden;
  top: 0;
  width: 50px;
}
</style>