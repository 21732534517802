import { createStore } from 'vuex'
import { settingsModule } from "@/store/settings";
import { userModule } from "@/store/user";
import { positionModelsModule } from "@/store/positionModels";

export default createStore({
  modules: {
    settings: settingsModule,
    user: userModule,
    positionModels: positionModelsModule,
  }
})
