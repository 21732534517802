<template>
  <div class="scene-main">
    <MediaContainers
        :room="room"
        :participants="participants"
    />

    <Renderer
        v-if="isShowAvatar"
        ref="renderer"
        antialias
        class="renderer"
        resize="window"
    >
      <TroisScene
          background="#FFF"
      >
        <PerspectiveCamera
            :position="{x: 0, y: 0, z: 0}"
        />
        <AmbientLight />
        <DirectionalLight :position="{ x: 0, y: 0, z: 0 }" />

        <Avatar
            v-if="!room"
            ref="selfAvatarRef"
            :participant="null"
        />
        <Avatar
            v-else-if="participants.length"
            v-for="participant in participants"
            :ref="getParticipantAvatarRef(participant)"
            :key="participant.identity"
            :participant="participant"
        />
      </TroisScene>
    </Renderer>
  </div>
</template>

<script>
import {
  Renderer,
  Scene as TroisScene,
  PerspectiveCamera,
  AmbientLight,
  DirectionalLight,
  GltfModel
} from 'troisjs';
import { Room } from "livekit-client";
import Avatar from "@/components/Avatar.vue";
import MediaContainers from "@/components/MediaContainers.vue";
import { mapState } from "vuex";

export default {
  name: 'Scene',
  components: {
    Renderer,
    TroisScene,
    PerspectiveCamera,
    AmbientLight,
    DirectionalLight,
    GltfModel,
    Avatar,
    MediaContainers,
  },
  props: {
    room: {
      type: Room,
      default: null,
    },
    participants: {
      default: [],
    }
  },
  computed: {
    ...mapState({
      isShowAvatar: state => state.settings.isShowAvatar,
    })
  },
  methods: {
    getParticipantAvatarRef (participant) {
      return `participantAvatar${participant.identity}Ref`;
    },
    rendererRequestAnimation () {
      const selfAvatar = this.$refs.selfAvatarRef;
      if (selfAvatar) selfAvatar.animate();

      if (this.room && this.participants.length) {
        this.participants.forEach(participant => {
          const ref = this.$refs[this.getParticipantAvatarRef(participant)];
          if (ref && ref[0]) ref[0].animate();
        });
      }

      if (!this.$refs.renderer) return;
      this.$refs.renderer.renderer.render(this.$refs.renderer.scene, this.$refs.renderer.camera);
    },
    startSceneAnimations () {
      const renderer = this.$refs.renderer;
      if (!renderer) return;
      renderer.onBeforeRender(this.rendererRequestAnimation);
    },
  },
  mounted() {
    this.startSceneAnimations();
  }
}
</script>

<style scoped></style>