<template>
  <div class="settings-wrapper">

    <div class="row-buttons">
      <button
        @click="handleChangeAnimated"
      >
        <img :src="animatedBtnIcon" alt="">
      </button>

      <button
          @click="handleChangeShowAvatar"
      >
        <img :src="showAvatarBtnIcon" alt="">
      </button>
    </div>

    <div class="row-buttons">

      <button
        v-if="!isConnectedToRoom"
        @click="handleConnect"
      >
        Подключится к комнате
      </button>
      <button
        v-else
        @click="handleDisconnect"
      >
        Отключиться от комнаты
      </button>

    </div>

    <div class="row-buttons">

      <button
          @click="setCameraMode"
          :disabled="isCameraMode"
      >
        <img src="/assets/icons/camera.svg" alt="">
      </button>

      <button
        @click="setMicroMode"
        :disabled="isMicroMode"
      >
        <img src="/assets/icons/micro.svg" alt="">
      </button>

    </div>

    <div class="row-buttons">
      <button
          v-for="glbUrlItem in glbUrlList"
          @click="handleChangeAvatar(glbUrlItem.url)"
          :key="'change-avatar-btn-' + glbUrlItem.name"
          :disabled="isGlbUrlChosen(glbUrlItem.url)"
          class="avatar-btn"
      >
        <img :src="glbUrlItem.labelIcon" alt="">
      </button>
    </div>

  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'Settings',
  computed: {
    ...mapState({
      isAnimated: state => state.settings.isAnimated,
      isShowAvatar: state => state.settings.isShowAvatar,
      isConnectedToRoom: state => state.user.isConnectedToRoom,
      glbUrlList: state => state.settings.glbUrlList,
      glbUrl: state => state.settings.glbUrl,
    }),

    ...mapGetters({
      isMicroMode: 'settings/isMicroMode',
      isCameraMode: 'settings/isCameraMode',
      isGlbUrlChosen: 'settings/isGlbUrlChosen'
    }),

    animatedBtnIcon () {
      if (this.isAnimated) return '/assets/icons/animation-off.svg';
      return '/assets/icons/animation-on.svg';
    },
    showAvatarBtnIcon () {
      if (this.isShowAvatar) return '/assets/icons/account-off.svg';
      return '/assets/icons/account-on.svg';
    },
  },
  methods: {
    ...mapMutations({
      handleChangeAnimated: 'settings/changeAnimated',
      handleChangeShowAvatar: 'settings/changeShowAvatar',
      handleChangeShowScene: 'settings/changeShowScene',
      setMicroMode: 'settings/setMicroMode',
      setCameraMode: 'settings/setCameraMode',
      changeAvatar: 'settings/changeAvatar',
    }),

    handleChangeAvatar (value) {
      this.changeAvatar(value);
    },
    handleConnect () {
      this.$emit('connectToRoom');
    },
    handleDisconnect () {
      this.$emit('disconnectFromRoom');
    }
  }
}
</script>

<style scoped>
.settings-wrapper {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.row-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.avatar-btn img {
  width: 50px;
}
</style>