export const positionModelsModule = {
    state: () => ({
        sizes: new Map(),
    }),
    mutations: {
        addSize (state, { key, value }) {
            state.sizes.set(key, value);
        },
        removeSize (state, key) {
            state.sizes.delete(key);
        }
    },
    actions: {

    },
    getters: {
        getSizeByKey: state => key => {
            return state.sizes.get(key);
        },
        getPositionByKey: state => username => {
            const x = 0;

            const sizes = {};
            state.sizes.forEach((value, key) => {
                sizes[key] = value;
            });

            let length = 0;
            for (const [key, value] of Object.entries(sizes)) {
                length += value.y;
            }

            let z = -1 * (length + 0.5);

            let elementPosition = 0;
            for (const [key, value] of Object.entries(sizes)) {
                if (key === username) break;
                elementPosition += value.y;
            }

            const y = elementPosition - length/2;

            return { x, y, z };
        },
    },
    namespaced: true,
};