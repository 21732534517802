<template>
  <GltfModel
    v-if="isShowAvatar"
    :src="isMe ? glbUrl : defaultGlbUrl"
    :key="isMe ? glbUrl : defaultGlbUrl"
    @load="gltfModelLoaded"
  />
</template>

<script>
import {
  Participant,
  createLocalVideoTrack,
  createLocalAudioTrack,
  ParticipantEvent
} from "livekit-client";
import { mapGetters, mapState, mapMutations } from "vuex";
import { GltfModel } from 'troisjs';
import * as THREE from "three";
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import {Euler, Matrix4} from "three";
import {
  CAMERA_MODE,
  MICRO_MODE,
  IS_SPEAKING_VALUE,
  VIDEO_CONT_PREFIX,
  AUDIO_CONT_PREFIX
} from '@/store/settings';

export default {
  name: 'Avatar',
  components: {
    GltfModel
  },
  props: {
    participant: {
      type: Participant,
      default: null,
    },
  },
  data () {
    return {
      stream: null,
      lastVideoTime: -1,

      defaultMorphTargetInfluences: null,
      headMesh: null,

      defaultHeadNodeRotation: null,
      headNode: null,

      model: null,

      faceAnimationFrameId: null,

      speechAnimation: {
        max: 0.5,
        min: 0,
        value: 0,
        delta: 0.1,
        interval: null,
        stopInterval: null,
        intervalMs: 16,
      },
      checkSpeechInterval: null,

      idleAnimation: '/assets/animations/BreathingIdle.fbx',
      talkingAnimation: '/assets/animations/Talking.fbx',
      threeClock: new THREE.Clock(),
      modelMixer: null,
      modelMixerAction: null,
      animationFrameId: null,

      localAudioTrack: null,
      localVideoTrack: null,

      videoRef: null,
      videoRefLoaded: false,
    }
  },
  computed: {
    ...mapState({
      glbUrl: state => state.settings.glbUrl,
      defaultGlbUrl: state => state.settings.defaultGlbUrl,
      mode: state => state.settings.mode,
      isShowAvatar: state => state.settings.isShowAvatar,
      isAnimated: state => state.settings.isAnimated,
    }),
    ...mapGetters({
      username: 'user/username',
      getPositionByKey: 'positionModels/getPositionByKey',
    }),

    isMe () {
      if (!this.participant) return true;
      return this.username === this.participant.identity;
    },

    isCameraMode () {
      if (this.isMe) return this.mode === CAMERA_MODE;
      return this.localMode === CAMERA_MODE;
    },
    isMicroMode () {
      if (this.isMe) return this.mode === MICRO_MODE;
      return this.localMode === MICRO_MODE;
    },
    localMode () {
      if (this.isMe) return this.mode;

      if (this.localVideoTrack) return CAMERA_MODE;
      if (this.localAudioTrack) return MICRO_MODE;

      return null;
    },
    position () {
      const username = this.isMe ? this.username : this.participant.identity;

      return this.getPositionByKey(username);
    }
  },
  watch: {
    localMode () {
      return this.handleChangeMode();
    },
    glbUrl () {
      return this.handleChangeAvatar();
    },
    isAnimated () {
      this.handleChangeIsAnimated();
    },
    isShowAvatar () {
      this.handleChangeShowAvatar();
    },
    model () {
      if (!this.model) return;
      this.addModelPosition();
    },
    position () {
      this.changeModelPosition();
    }
  },
  methods: {
    //COMMON
    ...mapMutations({
      addSize: 'positionModels/addSize',
      removeSize: 'positionModels/removeSize',
    }),

    clear (isClose = false) {
      this.clearStream();
      this.modelToDefault();
      if (!isClose) {
        this.setup();
      }
      if (isClose) {
        this.videoRefLoaded = false;
        if (this.stream) {
          this.stream.getTracks().forEach(track => {
            track.stop();
          });
          this.stream = null;
        }
        this.clearMediaContainer(false);
        this.clearMediaContainer(true);

        const username = this.isMe ? this.username : this.participant.identity;
        this.removeSize(username);
      }
    },
    handleChangeMode () {
      this.videoRefLoaded = false;
      if (this.stream) {
        this.stream.getTracks().forEach(track => {
          track.stop();
        });
        this.stream = null;
      }
      this.clearMediaContainer(false);
      if (this.localMode === MICRO_MODE) {
        this.clearMediaContainer(true);
      }
      this.clear();
    },
    handleChangeAvatar () {
      if (!this.isMe) return;
      this.clear();
    },
    handleChangeIsAnimated () {
      if (this.isAnimated) {
        const animation = this.isMicroMode ? this.talkingAnimation : this.idleAnimation;
        this.applyAnimation(animation);
        return;
      }

      if (this.modelMixer) {
        this.modelMixer.stopAllAction();
      }
    },
    handleChangeShowAvatar () {
      if (this.isShowAvatar) return;

      this.clear(true);
      this.model = null;
      this.defaultMorphTargetInfluences = null;
      this.defaultHeadNodeRotation = null;
      this.headNode = null;
    },
    modelToDefault () {
      if (this.defaultMorphTargetInfluences) {
        this.defaultMorphTargetInfluences.forEach((val, index) => {
          this.headMesh.morphTargetInfluences[index] = val;
        });
      }

      if (this.headNode) {
        this.headNode.rotation.x = this.defaultHeadNodeRotation.x;
        this.headNode.rotation.y = this.defaultHeadNodeRotation.y;
        this.headNode.rotation.z = this.defaultHeadNodeRotation.z;
      }
    },
    clearStream () {
      if (this.checkSpeechInterval) {
        clearInterval(this.checkSpeechInterval);
        this.checkSpeechInterval = null;
      }
      if (this.speechAnimation.interval) {
        clearInterval(this.speechAnimation.interval);
        this.speechAnimation.interval = null;
      }
      if (this.speechAnimation.stopInterval) {
        clearInterval(this.speechAnimation.stopInterval);
        this.speechAnimation.stopInterval = null;
      }
      if (this.modelMixer) {
        this.modelMixer.stopAllAction();
      }
      if (this.modelMixerAction) {
        this.modelMixerAction.stop();
        this.modelMixerAction = null;
      }
    },
    gltfModelLoaded (model) {
      this.model = model.scene;
      [...model.parser.associations].forEach(item => {
        const node = item[0];
        if (node.name === 'Wolf3D_Avatar') {
          if (node.morphTargetInfluences) {
            this.defaultMorphTargetInfluences = [...node.morphTargetInfluences];
          }
          this.headMesh = node;
        }
        if (node.name === 'Head') {
          this.defaultHeadNodeRotation = {x: node.rotation.x, y: node.rotation.y, z: node.rotation.z};
          this.headNode = node;
        }
      });

      this.modelMixer = new THREE.AnimationMixer(model.scene);

      this.setup();

      this.$emit('avatarModelLoaded');
    },
    async applyAnimation (animationSrc) {
      if (!this.model) return;

      if (this.modelMixer) {
        this.modelMixer.stopAllAction();
      }
      if (!this.isAnimated) return;

      const fbxLoader = new FBXLoader();

      const fbx = await fbxLoader.loadAsync(animationSrc);
      const fbxAnimation = fbx.animations[0];

      if (this.isCameraMode) {
        const excludeElements = [
          'Head.quaternion',
          'HeadTop_End.quaternion',
          'LeftEye.quaternion',
          'RightEye.quaternion'
        ];
        const filteredTracks = fbxAnimation.tracks.filter(item => {
          if (excludeElements.includes(item.name)) {
            return false;
          }
          return item;
        });
        fbxAnimation.tracks = [...filteredTracks];
      }

      if (this.modelMixer) {
        this.modelMixerAction = this.modelMixer.clipAction(fbxAnimation);
        this.modelMixerAction.play();
      }
    },
    async setup () {
      const fbxAnimationUrl = this.isMicroMode ? this.talkingAnimation : this.idleAnimation;
      this.applyAnimation(fbxAnimationUrl);

      if (!this.isMe) return;
      if (this.isCameraMode) {
        return await this.setupCamera();
      }
      if (this.isMicroMode) {
        return await this.setupMicro();
      }
    },
    async attachStartedTracks () {
      if (!this.participant) return;
      if (this.isMe) return;
      const publications = [...this.participant.trackPublications.values()];
      for (const publication of publications) {
        await this.attachRemoteTrack(publication.track);
      }
    },
    async unSubFromLocalTracks () {
      if (!this.participant) return;
      if (this.localAudioTrack) {
        await this.participant.unpublishTrack(this.localAudioTrack, true);
        await this.localAudioTrack.stop();
        this.localAudioTrack = null;
      }

      if (this.localVideoTrack) {
        await this.participant.unpublishTrack(this.localVideoTrack, true);
        await this.localVideoTrack.stop();
        this.localVideoTrack = null;
      }
    },
    async attachRemoteTrack (track) {
      const element = track.attach();
      const result = this.appendMediaElementToCont(element, track.kind === 'video');
      if (!result) return;

      if (track.kind === 'video') {
        this.localVideoTrack = track
      }
      if (track.kind === 'audio') {
        this.localAudioTrack = track;
      }
    },
    async attachLocalTrack (publication) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(publication.track.mediaStreamTrack);

      const element = this.createMediaElement(mediaStream, publication.kind === 'video');
      const result = this.appendMediaElementToCont(element, publication.kind === 'video');
      if (!result) return;

      if (publication.kind === 'video') {
        this.localVideoTrack = publication.track;
      }
      if (publication.kind === 'audio') {
        this.localAudioTrack = publication.track;
      }
    },
    async trackUnpublished (publication) {
      if (publication.kind === 'video') {
        this.localVideoTrack = null;
        this.videoRef = null;
      }
      if (publication.kind === 'audio') {
        this.localAudioTrack = null;
      }
    },
    async registerLiveKitEvents () {
      if (!this.participant) return;

      this.participant.on(ParticipantEvent.LocalTrackPublished, publication => this.attachLocalTrack(publication));

      this.participant.on(ParticipantEvent.TrackSubscribed, (track) => this.attachRemoteTrack(track));

      this.participant.on(ParticipantEvent.TrackUnpublished, track => this.trackUnpublished(track));

      this.participant.on(ParticipantEvent.IsSpeakingChanged, speaking => this.changeIsSpeakingValue(speaking));
    },

    async animateFaceLandMarker () {
      if (!this.isCameraMode ||
          !window.faceLandmarker ||
          !this.videoRef ||
          !this.model ||
          !this.headMesh ||
          !this.videoRefLoaded
      ) {
        return false;
      }

      const element = this.videoRef;
      const nowInMs = Date.now();

      if (this.lastVideoTime !== element.currentTime) {
        this.lastVideoTime = element.currentTime;
        const faceLandmarkerResult = window.faceLandmarker.detectForVideo(element, nowInMs);

        if (faceLandmarkerResult.faceBlendshapes && faceLandmarkerResult.faceBlendshapes.length > 0 && faceLandmarkerResult.faceBlendshapes[0].categories) {
          const matrix = new Matrix4().fromArray(faceLandmarkerResult.facialTransformationMatrixes[0].data);
          const rotation = new Euler().setFromRotationMatrix(matrix);

          const blendshapes = faceLandmarkerResult.faceBlendshapes[0].categories;
          if (!blendshapes.length || !rotation) return;

          blendshapes.forEach(element => {
            let index = this.headMesh.morphTargetDictionary[element.categoryName];
            if (index >= 0) {
              this.headMesh.morphTargetInfluences[index] = element.score;
            }
          });

          if (this.headNode) {
            this.headNode.rotation.x = rotation.x;
            this.headNode.rotation.y = rotation.y;
            this.headNode.rotation.z = rotation.z;
          }
        }
      }
    },

    idleAnimationRequest () {
      if (!this.model || !this.isAnimated || !this.modelMixer || !this.modelMixerAction) {
        return false;
      }

      const delta = this.threeClock.getDelta();
      this.modelMixer.update(delta);
    },

    animate () {
      this.animateFaceLandMarker();
      this.idleAnimationRequest();
    },

    getMediaContainerId (isVideo) {
      const prefix = isVideo ? VIDEO_CONT_PREFIX : AUDIO_CONT_PREFIX;
      const postfix = this.participant ? this.participant.identity : this.username;
      return prefix + postfix;
    },
    createMediaElement (stream, isVideo) {
      const element = isVideo ? document.createElement('video') : document.createElement('audio');
      element.autoplay = true;
      element.muted = true;
      if (isVideo) {
        element.playsInline = true;
        element.setAttribute('webkit-playsinline', true);
      }
      element.style.width = '50px';
      element.srcObject = stream;
      return element;
    },
    appendMediaElementToCont (element, isVideo) {
      element.style.width = '50px';
      const id = this.getMediaContainerId(isVideo);
      const container = document.getElementById(id);
      if (!container) return false;
      container.innerHTML = '';
      container.appendChild(element);
      if (isVideo) {
        element.addEventListener('loadeddata', () => {
          this.videoRefLoaded = true;
        });
        this.videoRef = element;
      }
      return true;
    },
    clearMediaContainer (isVideo) {
      if (isVideo) {
        this.videoRef = null;
        this.videoRefLoaded = false;
      }
      const id = this.getMediaContainerId(isVideo);
      const container = document.getElementById(id);
      if (!container) return false;
      container.innerHTML = '';
      return true;
    },
    addModelPosition () {
      if (!this.model || !this.username) return false;

      const box = new THREE.Box3().setFromObject(this.model);
      const size = box.getSize(new THREE.Vector3());

      const username = this.isMe ? this.username : this.participant.identity;

      this.addSize({ key: username, value: size });
    },
    changeModelPosition () {
      if (!this.model || !this.position) return false;

      this.model.position.set(this.position.x, this.position.y, this.position.z);
    },

    //CAMERA
    async setupCamera () {
      if (!this.isMe) return;

      if (this.participant) {
        await this.unSubFromLocalTracks();
        const videoTrack = await createLocalVideoTrack();
        const audioTrack = await createLocalAudioTrack();

        await this.participant.publishTrack(videoTrack);
        await this.participant.publishTrack(audioTrack);

      } else {

        if (this.videoRef && this.stream) return;
        navigator.mediaDevices.getUserMedia({
          video: { width: 1200, height: 700 },
          audio: false,
        })
            .then(stream => {
              this.stream = stream;
              const element = this.createMediaElement(stream, true);
              if (!element) return false;
              const result = this.appendMediaElementToCont(element, true);
              if (!result) return false;
            });
      }
    },

    //MICRO
    async setupMicro () {
      if (!this.isMe) return;

      if (this.participant) {
        await this.unSubFromLocalTracks();
        const audioTrack = await createLocalAudioTrack();
        await this.participant.publishTrack(audioTrack);

      } else {

        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
          this.stream = stream;
          const audioContext = new AudioContext();
          const source = audioContext.createMediaStreamSource(stream);
          const analyser = audioContext.createAnalyser();
          const dataArray = new Uint8Array(analyser.frequencyBinCount);
          source.connect(analyser);
          this.checkSpeechInterval = setInterval(() => {
            analyser.getByteFrequencyData(dataArray);
            let sum = 0;
            for (let i = 0; i < dataArray.length; i++) {
              sum += dataArray[i];
            }
            let average = sum / dataArray.length;
            const isSpeaking = average > IS_SPEAKING_VALUE;
            this.changeIsSpeakingValue(isSpeaking);
          }, 16);
        } catch (err) {
          console.error('Ошибка при доступе к аудиоустройству:', err);
        }

      }
    },
    changeIsSpeakingValue (isSpeaking) {
      if (isSpeaking) {
        if (this.speechAnimation.stopInterval) {
          clearInterval(this.speechAnimation.stopInterval);
          this.speechAnimation.stopInterval = null;
        }
        if (!this.speechAnimation.interval) {
          this.speechAnimation.interval = setInterval(this.speech, this.speechAnimation.intervalMs);
        }
        return;
      }

      if (this.speechAnimation.interval) {
        clearInterval(this.speechAnimation.interval);
        this.speechAnimation.interval = null;
      }
      if (!this.speechAnimation.stopInterval) {
        this.speechAnimation.stopInterval = setInterval(this.stopSpeech, this.speechAnimation.intervalMs);
      }
    },
    speech () {
      this.speechAnimation.value += this.speechAnimation.delta;

      if (this.speechAnimation.value <= this.speechAnimation.min) {
        this.speechAnimation.delta = Math.abs(this.speechAnimation.delta);
      } else if (this.speechAnimation.value >= this.speechAnimation.max) {
        this.speechAnimation.delta = -Math.abs(this.speechAnimation.delta);
      }

      this.applyMorphTargetInfluence();
    },
    stopSpeech () {
      this.speechAnimation.value -= Math.abs(this.speechAnimation.delta);
      if (this.speechAnimation.value < this.speechAnimation.min) {
        this.speechAnimation.value = -this.speechAnimation.min;
        if (this.speechAnimation.stopInterval) {
          clearInterval(this.speechAnimation.stopInterval);
          this.speechAnimation.stopInterval = null;
        }
      }
      this.applyMorphTargetInfluence();
    },
    applyMorphTargetInfluence() {
      const jawOpenedIndex = this.headMesh.morphTargetDictionary['jawOpen'];
      if (jawOpenedIndex >= 0) {
        this.headMesh.morphTargetInfluences[jawOpenedIndex] = this.speechAnimation.value;
      }
    },
  },
  mounted () {
    this.registerLiveKitEvents();
    this.attachStartedTracks();
  },
  beforeUnmount() {
    this.clear(true);
  }
}
</script>

<style scoped></style>