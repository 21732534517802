export const userModule = {
    state: () => ({
        isConnectedToRoom: false,
    }),
    mutations: {
        changeIsConnectedToRoom (state, value) {
            state.isConnectedToRoom = value;
        }
    },
    actions: {},
    getters: {
        username () {
            if (localStorage.getItem('3d-avatar-username')) return localStorage.getItem('3d-avatar-username');

            const username = (Math.random() + 1).toString(36).substring(7);
            localStorage.setItem('3d-avatar-username', username);

            return username;
        },
    },
    namespaced: true,
};