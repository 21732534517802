import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import { TroisJSVuePlugin } from 'troisjs';

const app = createApp(App);

app.use(TroisJSVuePlugin);
app.use(store);

app.config.devtools = true;

app.mount('#app')
