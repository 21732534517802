export const GLB_URL_PARAMS = '?morphTargets=ARKit&textureAtlas=1024';
export const CAMERA_MODE = 'CAMERA';
export const MICRO_MODE = 'MICRO';
export const IS_SPEAKING_VALUE = 5;

export const VIDEO_CONT_PREFIX = 'mediaVideoContainer';
export const AUDIO_CONT_PREFIX = 'mediaAudioContainer';

export const settingsModule = {
    state: () => ({
        mode: null,
        isAnimated: true,
        isShowAvatar: true,
        glbUrlList: [
            {
                name: 'Avatar 1',
                url: 'https://models.readyplayer.me/65fd4cb9b526f65083a47648.glb',
                labelIcon: 'https://models.readyplayer.me/65fd4cb9b526f65083a47648.png',
            },
            {
                name: 'Avatar 2',
                url: 'https://models.readyplayer.me/660d1faf8721f1c58fa71a50.glb',
                labelIcon: 'https://models.readyplayer.me/660d1faf8721f1c58fa71a50.png',
            },
            {
                name: 'Avatar 3',
                url: 'https://models.readyplayer.me/6460d95f9ae10f45bffb2864.glb',
                labelIcon: 'https://models.readyplayer.me/6460d95f9ae10f45bffb2864.png',
            },
        ],
        glbUrl: `https://models.readyplayer.me/6460d95f9ae10f45bffb2864.glb?=ARKit&textureAtlas=1024`,
        defaultGlbUrl: `https://models.readyplayer.me/6460d95f9ae10f45bffb2864.glb?=ARKit&textureAtlas=1024`,
    }),
    mutations: {
        changeAnimated (state) {
            state.isAnimated = !state.isAnimated;
        },
        changeShowAvatar (state) {
            state.isShowAvatar = !state.isShowAvatar;
        },
        setCameraMode (state) {
            state.mode = CAMERA_MODE;
        },
        setMicroMode (state) {
            state.mode = MICRO_MODE;
        },
        changeAvatar (state, value) {
            state.glbUrl = `${value}${GLB_URL_PARAMS}`
        }
    },
    actions: {

    },
    getters: {
        isMicroMode (state) {
            return state.mode === MICRO_MODE;
        },
        isCameraMode (state) {
            return state.mode === CAMERA_MODE;
        },
        isGlbUrlChosen: state => value => {
            return state.glbUrl === (value + GLB_URL_PARAMS)
        }
    },
    namespaced: true
};