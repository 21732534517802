<template>
  <div class="main">
    <Scene
      :room="room"
      :participants="participants"
    />

    <Settings
        @connectToRoom="handleConnect"
        @disconnectFromRoom="handleDisconnect"
    />
  </div>
</template>

<script>
import Settings from "@/components/Settings.vue";
import Scene from "@/components/Scene.vue";
import {FaceLandmarker, FilesetResolver} from "@mediapipe/tasks-vision";
import { Room, RoomEvent } from 'livekit-client';
import { mapGetters, mapMutations } from 'vuex';

window.faceLandmarker = null;
export default {
  name: 'MainMain',
  components: {
    Settings,
    Scene,
  },
  data () {
    return {
      room: null,
      participants: [],
    };
  },
  computed: {
    ...mapGetters({
      username: 'user/username'
    })
  },
  methods: {
    ...mapMutations({
      handleChangeIsConnectedToRoom: 'user/changeIsConnectedToRoom'
    }),

    async initFaceLandMaker () {
      if (window.faceLandmarker) return;

      const vision = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm");
      window.faceLandmarker = await FaceLandmarker.createFromOptions(vision, {
        baseOptions: {
          modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
          delegate: "GPU"
        },
        numFaces: 10,
        runningMode: "VIDEO",
        outputFaceBlendshapes: true,
        outputFacialTransformationMatrixes: true,
      });
    },
    async connectToRoom () {
      try {
        const result = await fetch(`${process.env.VUE_APP_API_URL}?name=${this.username}`);
        if (!result.ok) return false;
        const token = await result.json();
        if (!token) return false;

        const room = new Room();
        await room.connect(process.env.VUE_APP_LIVE_LIT_CONNECT_URL, token);

        this.room = room;

        this.getRoomParticipants();

        this.registerEvents();

        this.handleChangeIsConnectedToRoom(true);
      } catch (e) {
        this.room = null;
        console.log('Не удалось подключится к комнате');
        console.error(e);
      }
    },
    registerEvents () {
      if (!this.room) return;

      this.room.on(RoomEvent.ParticipantConnected, this.getRoomParticipants);

      this.room.on(RoomEvent.ParticipantDisconnected, item => this.disconnectedFromRoom(item))
    },
    getRoomParticipants () {
      this.participants = [this.room.localParticipant, ...this.room.remoteParticipants.values()];
    },
    disconnectedFromRoom (item) {
      if (this.username !== item.identity) {
        this.getRoomParticipants();
        return;
      }
      this.room = null;
    },
    handleConnect () {
      this.connectToRoom();
    },
    handleDisconnect () {
      if (!this.room) return;
      this.room.disconnect();
      this.room = null;
      this.handleChangeIsConnectedToRoom(false);
    },
  },
  mounted() {
    this.initFaceLandMaker();
  },
  beforeUnmount() {
    this.handleDisconnect();
  }
}
</script>

<style>
</style>
